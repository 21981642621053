<template>
    <div>
        <!-- <ds-header title="招商房源管理" :showBack="true"></ds-header> -->
        <a-form layout="inline" style="margin-bottom: 20px">
            <a-form-item label="位置">
                <a-tree-select style="width: 200px" v-model="searchForm.spaceId" :tree-default-expand-all="false"
                    allow-clear :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }" :tree-data="roomPlaceList"
                    placeholder="请选择位置" :replaceFields="roomPlaceFieldName" :getPopupContainer="(triggerNode) => {
                        return triggerNode.parentNode || document.body;
                    }">
                </a-tree-select>
            </a-form-item>
            <a-form-item label="空间名称">
                <a-input v-model.trim="searchForm.spaceName" placeholder="请输入空间名称" />
            </a-form-item>
            <a-form-item label="房源状态">
                <a-select placeholder="请选择房源状态" :getPopupContainer="(triggerNode) => {
                    return triggerNode.parentNode || document.body;
                }
                    " v-model="searchForm.housingStatus" style="width: 200px">
                    <a-select-option v-for="item in housingStatusList" :value="item.value" :key="item.value">
                        {{ item.label }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="楼栋类型">
                <a-select placeholder="请选择楼栋类型" :getPopupContainer="(triggerNode) => {
                    return triggerNode.parentNode || document.body;
                }
                    " v-model="searchForm.buildingType" style="width: 200px">
                    <a-select-option v-for="item in houseTypeList" :value="item.value" :key="item.value">
                        {{ item.label }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="层高">
                <a-input-number style="width: 150px;" :min="0" :precision="0" v-model.trim="searchForm.heightMin"
                    placeholder="请输入最低值" /> -
                <a-input-number style="width: 150px;" :min="searchForm.heightMin" :precision="0"
                    v-model.trim="searchForm.heightMax" placeholder="请输入最高值" /> 米
            </a-form-item>
            <a-form-item label="承重">
                <a-input-number style="width: 150px;" :min="0" :precision="0" v-model.trim="searchForm.weightMin"
                    placeholder="请输入最低值" /> -
                <a-input-number style="width: 150px;" :min="searchForm.weightMin" :precision="0"
                    v-model.trim="searchForm.weightMax" placeholder="请输入最高值" />
                KN/㎡
            </a-form-item>
            <a-form-item label="可租面积">
                <a-input-number style="width: 150px;" :min="0" :precision="0" v-model.trim="searchForm.rentAreaMin"
                    placeholder="请输入最低值" /> -
                <a-input-number style="width: 150px;" :min="searchForm.rentAreaMin" :precision="0"
                    v-model.trim="searchForm.rentAreaMax" placeholder="请输入最高值" />
                ㎡
            </a-form-item>
            <a-form-item label="实际可租赁面积">
                <a-input-number style="width: 150px;" :min="0" :precision="0" v-model.trim="searchForm.actualLeasableMinArea"
                    placeholder="请输入最低值" /> -
                <a-input-number style="width: 150px;" :min="searchForm.actualLeasableMinArea" :precision="0"
                    v-model.trim="searchForm.actualLeasableMaxArea" placeholder="请输入最高值" />
                ㎡
            </a-form-item>

        </a-form>
        <div class="button">
            <div><a-button type="primary" @click="serch">
                    <a-icon type="search" />
                    查询
                </a-button>
                <a-button class="marginLeft10" @click="searchClear">
                    <a-icon type="redo" />
                    重置
                </a-button>
            </div>
        </div>
        <a-table :scroll="{ x: 1700 }" :row-selection="{
            selectedRowKeys: selectedRowKeys, onChange: onSelectChange, type: 'radio', getCheckboxProps: (record) => {
                return {
                    props: {
                        disabled: !record.rentalPrice // 禁选项
                    }
                };
            }
        }" style="margin-top: 10px" :columns="columns" @change="pageChange"
            :pagination="pagination.total ? pagination : false" :data-source="dataList" bordered :loading="false"
            :rowKey="(record) => record.spaceId">
        </a-table>

    </div>
</template>

<script>
import moment from 'moment';
import * as api from "@/api/lease";
import store from "@/store";
import { selectColumns, fieldName, houseTypeList, housingStatusList } from "../../index"
export default {
    data() {
        return {
            columns: selectColumns,
            houseTypeList,
            roomPlaceFieldName: {
                children: 'spaceExtensionInfoList',
                title: 'spaceName',
                key: 'id',
                value: 'id',
            },
            housingStatusList,
            dataList: [{}],
            fieldName,
            searchForm: {
                spaceId: undefined,//空间位置id
                heightMin: null,//层高最低
                heightMax: null,//层高最高
                weightMax: null,//承重最高
                weightMin: null,//承重最低
                rentAreaMin: null,//可租面积最低
                rentAreaMax: null,//可租面积最高
                actualLeasableMinArea: null,//套内面积最低
                actualLeasableMinArea: null,//套内面积最高
            },
            roomPlaceList: [],
            selectedRowKeys: [],
            selectedRow: {},
            //分页数据
            pagination: {
                showSizeChanger: true,
                current: 1,
                pageSize: 10,
                total: 0,
                showQuickJumper: true,
                showTotal: (total) => {
                    return `共 ${total} 条`;
                },
            },
        };
    },
    mounted() {
        this.getSpaceList()
        this.getData();

    },
    methods: {
        //分页操作
        pageChange(option) {
            this.pagination.current = option.current;
            this.pagination.pageSize = option.pageSize;
            this.getData();
        },
        serch() {
            this.pagination.current = 1;
            this.getData()
        },
        searchClear() {
            this.searchForm = this.$options.data.call(this).searchForm;
            this.pagination.current = 1;
            this.pagination.pageSize = 10;
            this.getData()
        },
        async getData() {
            let obj = {
                pageNum: this.pagination.current,
                pageSize: this.pagination.pageSize,
                ...this.searchForm,
                roleId: store.state.common.currentMenuRoleId,
            }
            await api.getSpaceCommonComponent(obj).then((res) => {
                if (res.code === '200' && res.data) {
                    this.dataList = res.data.items;
                    this.pagination.total = res.data.total;
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                }
            });
        },
        async getSpaceList() {
            await api.getSpaceInfoBySpaceIds({ roleId: store.state.common.currentMenuRoleId }).then((res) => {
                if (res.code === '200') {
                    this.roomPlaceList = res.data
                }
            });
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys
            this.dataList.forEach(item => {
                if (item.spaceId == selectedRowKeys) {
                    this.selectedRow = item
                }
            })
        }

    },
};
</script>

<style scoped lang="scss">
.button {
    display: flex;
    justify-content: flex-end;
}

.pageSwitch {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
}

.marginLeft10 {
    margin-left: 10px;
}
</style>